require("@rails/ujs").start() // rails-ujs()
// turbolinkを有効にしていれば、この行にrequire("turbolinks").start()
require("@rails/activestorage").start() //activeStrorage周りのjs
//require("channels") // Webhook Subscribeの仕組み
 
 
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
 
// require("trix") //actiontextの実装としてのJSライブラリ
require("@rails/actiontext")
import Trix from "trix"

document.addEventListener("trix-initialize", function(event) {
  var element = document.querySelector("trix-editor");

  setCustomTrixConfig();

  // Trix.configに追加したHTMLタグを編集画面でも使用するために、content(本文)をtextareaに再挿入しています
  var content = element.dataset.content;
  if (content) {
    element.editor.loadHTML(content);
  }

  hideDefalutButton();

  addCustomButton();
});

// Trix.configに使用したいHTMLタグを追加します
function setCustomTrixConfig() {
  Trix.config.blockAttributes.heading2 = {
    tagName: "h2",
    terminal: true,
    breakOnReturn: true,
    group: false,
  };

  Trix.config.blockAttributes.heading3 = {
    tagName: "h3",
    terminal: true,
    breakOnReturn: true,
    group: false,
  };
}

// デフォルトで存在するHTMLタグを画面に表示しないようにします
function hideDefalutButton() {
  var H1 = event.target.toolbarElement.querySelector('.trix-button--icon-heading-1');

  var targetButtons = [];
  targetButtons.push(H1);

  hideButton(targetButtons);
}

// 引数で受け取った対象を全て隠します
function hideButton(targets) {
  if (targets.length) {
    targets.forEach((target) => {
      target.style.display = 'none';
    });
  }
}

// HTMLタグを扱うためのボタンを追加します
function addCustomButton() {
  var buttonH1 =
    '<button type="button" data-trix-attribute="heading1" title="Heading 1" class="trix-button trix-button--icon" style="text-indent: 1px;">H1</button>';
  var buttonH2 =
    '<button type="button" data-trix-attribute="heading2" title="Heading 2" class="trix-button trix-button--icon" style="text-indent: 1px;">H2</button>';
  var buttonH3 =
    '<button type="button" data-trix-attribute="heading3" title="Heading 3" class="trix-button trix-button--icon" style="text-indent: 1px;">H3</button>';

  const bar = event.target.toolbarElement.querySelector(".trix-button-group--block-tools");

  bar.insertAdjacentHTML("afterbegin", buttonH1);
  bar.insertAdjacentHTML("afterbegin", buttonH2);
  bar.insertAdjacentHTML("afterbegin", buttonH3);
}
